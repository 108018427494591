var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import CodeMirror from "codemirror";
import "codemirror/mode/swift/swift";
import "codemirror/lib/codemirror.css";
import { SwiftRuntime } from "javascript-kit-swift";
import kDefaultDemoScript from './demo.swift?raw';
var kCompileApi = "https://swiftwasm-compiler-api-mgv5x4syda-uc.a.run.app";
var kPrecompiledDemo = true;
var kDownloadUrls = {
    macos: ["macOS", "https://github.com/swiftwasm/swift/releases"],
    linux: ["Linux", "https://github.com/swiftwasm/swift/releases"],
};
var codeMirror = null;
var runButton = null;
var outputArea = null;
var downloadWasmButton = null;
var currentDownloadURL = null;
function writeOutputArea(text) {
    var element = document.getElementById("output-area");
    if (arguments.length > 1)
        text = Array.prototype.slice.call(arguments).join(" ");
    console.log(text);
    if (element) {
        element.textContent += text;
        element.scrollTop = element.scrollHeight; // focus on bottom
    }
}
function pageLoaded() {
    var codeArea = document.getElementById("code-area");
    codeArea.disabled = false;
    if (codeArea.value == "") {
        codeArea.value = kDefaultDemoScript;
    }
    codeMirror = CodeMirror.fromTextArea(codeArea, {
        mode: "swift",
        indentUnit: 4,
        lineNumbers: true,
    });
    codeMirror.setSize("100%", "500px");
    runButton = document.getElementById("code-run");
    runButton.addEventListener("click", runClicked);
    outputArea = document.getElementById("output-area");
    downloadWasmButton = document.getElementById("code-download-wasm");
    setupDownloadArea();
    document.getElementById("emaillink").href =
        "mailto:" + atob("aGVsbG9Ac3dpZnR3YXNtLm9yZw==");
}
function runClicked() {
    return __awaiter(this, void 0, void 0, function () {
        var code, compileResult, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    runButton.disabled = true;
                    downloadWasmButton.style.display = "none";
                    outputArea.textContent = "Compiling...";
                    if (currentDownloadURL) {
                        URL.revokeObjectURL(currentDownloadURL);
                    }
                    code = codeMirror.getValue();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, compileCode(code)];
                case 2:
                    compileResult = _a.sent();
                    populateResultsArea(compileResult);
                    if (compileResult.success) {
                        runWasm(compileResult.binary);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    console.log(e_1);
                    return [3 /*break*/, 4];
                case 4:
                    runButton.disabled = false;
                    return [2 /*return*/];
            }
        });
    });
}
function compileCode(code) {
    return __awaiter(this, void 0, void 0, function () {
        var fetchResult, resultBuffer, error;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(kPrecompiledDemo && code.trim() == kDefaultDemoScript.trim())) return [3 /*break*/, 2];
                    return [4 /*yield*/, getPrecompiledDemo()];
                case 1: return [2 /*return*/, _a.sent()];
                case 2: return [4 /*yield*/, fetch(kCompileApi, {
                        method: "POST",
                        body: JSON.stringify({
                            mainCode: code,
                            action: "emitExecutable"
                        }),
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })];
                case 3:
                    fetchResult = _a.sent();
                    if (!fetchResult.ok) return [3 /*break*/, 5];
                    return [4 /*yield*/, fetchResult.arrayBuffer()];
                case 4:
                    resultBuffer = _a.sent();
                    return [2 /*return*/, { binary: resultBuffer, success: true }];
                case 5:
                    return [4 /*yield*/, fetchResult.json()];
                case 6:
                    error = _a.sent();
                    return [2 /*return*/, { success: false, output: error.stderr }];
            }
        });
    });
}
export var wrapWASI = function (wasiObject) {
    // PATCH: @wasmer-js/wasi@0.x forgets to call `refreshMemory` in `clock_res_get`,
    // which writes its result to memory view. Without the refresh the memory view,
    // it accesses a detached array buffer if the memory is grown by malloc.
    // But they wasmer team discarded the 0.x codebase at all and replaced it with
    // a new implementation written in Rust. The new version 1.x is really unstable
    // and not production-ready as far as katei investigated in Apr 2022.
    // So override the broken implementation of `clock_res_get` here instead of
    // fixing the wasi polyfill.
    // Reference: https://github.com/wasmerio/wasmer-js/blob/55fa8c17c56348c312a8bd23c69054b1aa633891/packages/wasi/src/index.ts#L557
    var original_clock_res_get = wasiObject.wasiImport["clock_res_get"];
    wasiObject.wasiImport["clock_res_get"] = function () {
        wasiObject.refreshMemory();
        return Reflect.apply(original_clock_res_get, this, arguments);
    };
    return wasiObject.wasiImport;
};
function runWasm(wasmBuffer) {
    return __awaiter(this, void 0, void 0, function () {
        var WasmFs, wasmFs, decoder, WASI, wasi, swift, instance;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    writeOutputArea("Running WebAssembly...\n");
                    return [4 /*yield*/, import("@wasmer/wasmfs")];
                case 1:
                    WasmFs = (_a.sent()).WasmFs;
                    wasmFs = new WasmFs();
                    decoder = new TextDecoder("utf-8");
                    wasmFs.volume.fds[1].node.write = function (stdoutBuffer) {
                        var text = decoder.decode(stdoutBuffer);
                        writeOutputArea(text);
                        return stdoutBuffer.byteLength;
                    };
                    wasmFs.volume.fds[2].node.write = function (stderrBuffer) {
                        var text = decoder.decode(stderrBuffer);
                        console.error(text);
                        return stderrBuffer.byteLength;
                    };
                    return [4 /*yield*/, import("@wasmer/wasi")];
                case 2:
                    WASI = (_a.sent()).WASI;
                    wasi = new WASI({
                        bindings: __assign(__assign({}, WASI.defaultBindings), { fs: wasmFs.fs }),
                    });
                    swift = new SwiftRuntime();
                    return [4 /*yield*/, WebAssembly.instantiate(wasmBuffer, {
                            wasi_snapshot_preview1: wasi.wasiImport,
                            wasi_unstable: wasi.wasiImport,
                            javascript_kit: swift.wasmImports,
                        })];
                case 3:
                    instance = (_a.sent()).instance;
                    wasi.setMemory(instance.exports.memory);
                    instance.exports._initialize();
                    if (instance.exports.swjs_library_version) {
                        swift.setInstance(instance);
                    }
                    instance.exports.main();
                    return [2 /*return*/];
            }
        });
    });
}
function populateResultsArea(compileResult) {
    console.log(compileResult);
    var output = compileResult;
    if (output.success === false) {
        outputArea.textContent = output.output;
    }
    else {
        outputArea.textContent = "";
    }
    downloadWasmButton.style.display = output.success ? "" : "none";
    if (output.success) {
        var blob = new Blob([output.binary], { type: "application/wasm" });
        currentDownloadURL = URL.createObjectURL(blob);
        downloadWasmButton.href = currentDownloadURL;
    }
}
function setupDownloadArea() {
    var downloadButton = document.getElementById("download-button");
    var platform = detectPlatform();
    var isWindows = platform == "windows";
    var platformActual = isWindows ? "linux" : platform;
    var platformName = isWindows ? "Windows" : kDownloadUrls[platformActual][0];
    var downloadUrl = kDownloadUrls[platformActual][1];
    downloadButton.textContent = "Download for " + platformName;
    downloadButton.href = downloadUrl;
    if (isWindows) {
        document.getElementById("windows-wsl").textContent =
            "Requires Windows Subsystem for Linux. ";
    }
}
function detectPlatform() {
    var userAgent = navigator.userAgent;
    if (userAgent.indexOf("Mac OS X") != -1) {
        return "macos";
    }
    if (userAgent.indexOf("Windows") != -1) {
        return "windows";
    }
    return "linux";
}
function getPrecompiledDemo() {
    return __awaiter(this, void 0, void 0, function () {
        var fetchResult, resultBuffer;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch("/demo_compiled/program.wasm.txt")];
                case 1:
                    fetchResult = _a.sent();
                    return [4 /*yield*/, fetchResult.arrayBuffer()];
                case 2:
                    resultBuffer = _a.sent();
                    return [2 /*return*/, {
                            success: true,
                            binary: resultBuffer,
                        }];
            }
        });
    });
}
pageLoaded();
